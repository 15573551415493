body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.aha-logo {
  background: url(/static/media/aha-heart.aa719a1b.svg) no-repeat !important;
  width: 4.4rem;
  min-height: 5.5625rem;
  position: relative;
  color: #222328;
}

.App {
  text-align: center;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.textAreaWrapper {
  margin-top: 5rem;
  margin-bottom: 5rem;
}

h1 {
  margin: 0;
  font-size: 2.5rem;
}

p {
  font-size: 1.125rem;
  color: #333;
}

h2 {
  font-size: 1.5rem;
  margin-top: 2rem;
  color: #333;
}

ol {
  margin-left: 2rem;
  font-size: 1.125rem;
}

pre {
  background-color: #f5f5f5;
  padding: 1rem;
  border-radius: 5px;
  overflow-x: auto;
  font-size: 1rem;
}

